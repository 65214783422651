import { Container, Row, Col } from "react-bootstrap";
import "./Description.scss";

function Description() {
  return (
    <>
     <div className="mt-0" />
      <div className="font-white">
        <Container>
          <Row>
            <Col>
              แทงบอลออนไลน์บนมือถือ ผ่าน เว็บแทงบอลออนไลน์ ที่ดีที่สุด อย่าง
              LSM888 เว็บไซต์ใหญ่ จ่ายจริง จ่ายไม่ยั้ง
              พวกเราสามารถคิดแผนพนันด้วยข้อตกลงที่มีความยืดหยุ่นสูงที่สุด
              ทั้งยูฟ่าเบทยังมีโปรแกรมการ ฝาก-ถอนไม่มีอย่างต่ำ
              ยิ่งช่วยเพิ่มความยืดหยุ่นให้กับผู้เข้าร่วมพนันทุกกรุ๊ปทุกข้อตกลงมีทุนน้อย
              ก็สามารถพนันได้มีทุนมากมายก็จะยิ่งได้โอกาสทำเงินได้มากเพิ่มขึ้นกับข้อตกลงของการวางเดิมพันในรูปแบบนี้
              ซึ่งนับว่ามีเฉพาะในเว็บไซต์ใหญ่อย่าง LSM 888 เพียงแค่นั้นที่กล้าให้โอกาสให้นักเสี่ยงดวงได้สร้างจังหวะสำหรับในการร่วมวางเดิมพันโดยเพศผู้พนัน
              จะเป็นผู้กำหนดข้อแม้ได้เองทั้งสิ้น
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Description;
