import React, { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import './GoTop.scss'

function GoTop() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
         <IoIosArrowUp style={{marginTop: -12}}/>
        </button>
      )}
    </div>
  );
}

export default GoTop;
