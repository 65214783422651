import "./Home.scss";
import Carousels from "./Carousels";
import Description from "./Description"
import { Container, Col, Row } from "react-bootstrap";
function Home() {
  return (
    <div>
      {/* <div className="container">
        <div className="box box--1"></div>
        <div className="box box--2"></div>
        <div className="box box--3"></div>
        <div className="box box--4"></div>
        <div className="box box--5"></div>
      </div> */}

      <Container className="gray-space">
        <Row>
          <Col>
            <Carousels />
            <Description />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
