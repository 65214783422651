import "./App.scss";
import NavbarHeader from "./components/shared/NavbarHeader";
import GoTop from './components/shared/GoTop'
import Footer from "./components/shared/Footer";

// import Navs from "./components/pages/home/Navs";
// import Footer from "./components/pages/home/Footer";
// import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
// import Recommend from "./components/pages/product/Recommend";
// import Home from "./components/pages/home/Home";

function App() {
  return (
    // <BrowserRouter>
    //   <Navs />
    //   <Routes>
    //     <Route exact path="/" element={<Home />} />
    //     <Route path="/recommend" element={<Recommend />} />
    //   </Routes>
    //   <Footer />
    // </BrowserRouter>
    <>
      <NavbarHeader />
         
      <GoTop />
      <Footer />
    </>
  );
}

export default App;
