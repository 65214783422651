import { Container, Col, Row } from "react-bootstrap";
import Logo from "../../asset/images/logo-lsm.png";
import Line from "../../asset/images/icon/Line.png";
import Download from "../../asset/images/icon/download.png";
import "./Footer.scss";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="mt-0" />
        <Container>
          <Row>
            <Col xs={12} lg={4} md={4} className="d-flex justify-content-center">
              <img src={Logo} className="image-size" alt="Logo" />
            </Col>
            <Col xs={12} lg={4} md={4} className="font-footer d-flex justify-content-center">
              บริการลูกค้าทุกวัน 7 วัน 24 ชั่วโมง ไม่มีวันหยุด (7x24hr) บาคาร่า
              คาสิโนออนไลน์ ที่ดีที่สุด ทุกสิ่งทุกอย่าง บาคาร่าออนไลน์ รูเล็ต
              ไฮโล เสือมังกร ถ่ายทอดสดส่งตรงจากไต้หวัน บริการด้วยทีมงานมืออาชีพ
              ตลอด 24 ชม. สนุกสนานไปกับ สล็อตออนไลน์ แจ็ตพอตแตกทุกวันมากที่สุด
            </Col>

            <Col xs={12} lg={4} md={4} className="font-footer">
              <div className="d-flex justify-content-center">
                <img src={Line} className="line-image" alt="HeaderLine" />
              </div>
              <div className="mt-1" />
              <div className="d-flex justify-content-center">
                <img src={Download} className="line-image" alt="Line" />
              </div>
              <div className="mt-1" />
              <div className="d-flex justify-content-center">
                <span> LINE : @L888th</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
