import { Carousel } from "react-bootstrap";
import React, { useState } from "react";
import "./Carousels.scss";
import Home1 from "../../../asset/images/home/home1.jpg";
import Home2 from "../../../asset/images/home/home2.jpg";
import Home3 from "../../../asset/images/home/home3.jpg";
import Home4 from "../../../asset/images/home/home4.jpg";
import Home5 from "../../../asset/images/home/home5.jpg";

function Carousels() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item interval={3000}>
              <img className="d-block w-100" src={Home1} alt="First slide" />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img className="d-block w-100" src={Home2} alt="Second slide" />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img className="d-block w-100" src={Home3} alt="Third slide" />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img className="d-block w-100" src={Home4} alt="Third slide" />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img className="d-block w-100" src={Home5} alt="Third slide" />
            </Carousel.Item>
          </Carousel>
  );
}

export default Carousels;
