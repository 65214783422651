import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from "../pages/home/Home";
import Recommend from "../pages/product/Recommend";
import Logo from "../../asset/images/logo-lsm.png";
import './NavbarHeader.scss'

function NavbarHeader() {
  return (
    <BrowserRouter>
      <Navbar bg="dark" variant="dark" expand="xxl">
        <Container>
          <Navbar.Brand as={Link} to={"/home"}>
            <img
              alt=""
              src={Logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
           LSM 888 TH
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to={"/home"}>
                หน้าหลัก
              </Nav.Link>
              <Nav.Link as={Link} to={"/recommend"}>
                โปรโมชั่น
              </Nav.Link>
              <Nav.Link href="https://lin.ee/c5NKuWh">
                ติดต่อเรา
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/recommend" element={<Recommend />} />
      </Routes>
    </BrowserRouter>
  );
}

export default NavbarHeader;
