import { Image, Container, Row, Col } from "react-bootstrap";
import Image1 from "../../../asset/images/promotion/image-1.jpg";
import Image2 from "../../../asset/images/promotion/image-2.jpg";
import Image3 from "../../../asset/images/promotion/image-3.jpg";

import Image4 from "../../../asset/images/promotion/image-4.jpg";
import Image5 from "../../../asset/images/promotion/image-5.jpg";
import Image6 from "../../../asset/images/promotion/image-6.jpg";

import Image7 from "../../../asset/images/promotion/image-7.jpg";
import Image8 from "../../../asset/images/promotion/image-8.jpg";
import Image9 from "../../../asset/images/promotion/image-9.jpg";

import Image10 from "../../../asset/images/promotion/image-10.jpg";
import Image11 from "../../../asset/images/promotion/image-11.jpg";
import Image12 from "../../../asset/images/promotion/image-12.jpg";

import Image13 from "../../../asset/images/promotion/image-13.jpg";
import Image14 from "../../../asset/images/promotion/image-14.jpg";
import Image15 from "../../../asset/images/promotion/image-15.jpg";

import Image16 from "../../../asset/images/promotion/image-16.jpg";
import Image17 from "../../../asset/images/promotion/image-17.jpg";
import Image18 from "../../../asset/images/promotion/image-18.jpg";

import "./Recommend.scss";
function Recommend() {
  return (
    <div >
      <Container className="gray-space">
        <Row>
          <Col xs={12}  lg={4}>
            <Image className="responsive" src={Image1} />
          </Col>
          
          <Col xs={12}  lg={4}>
            <Image className="responsive" src={Image2} />
          </Col>
        
          <Col xs={12}  lg={4}>
            <Image className="responsive" src={Image3} />
          </Col>
        </Row>
        <div className="mt-0" />


        <Row>
          <Col xs={12} md={4}>
            <Image className="responsive" src={Image4} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image5} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image6} />
          </Col>

        </Row>

        <div className="mt-0" />
        <Row>
          <Col xs={12} md={4}>
            <Image className="responsive" src={Image7} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image8} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image9} />
          </Col>
        </Row>



        <div className="mt-0" />
        <Row>
          <Col xs={12} md={4}>
            <Image className="responsive" src={Image10} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image11} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image12} />
          </Col>
        </Row>


        <div className="mt-0" />
        <Row>
          <Col xs={12} md={4}>
            <Image className="responsive" src={Image13} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image14} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image15} />
          </Col>
          <Col xs={12} md={4}></Col>
        </Row>
        
        <div className="mt-0" />
        <Row>
          <Col xs={12} md={4}>
            <Image className="responsive" src={Image16} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image17} />
          </Col>

          <Col xs={12} md={4}>
            <Image className="responsive" src={Image18} />
          </Col>
          <Col xs={12} md={4}></Col>
        </Row>



      </Container>
    </div>
  );
}

export default Recommend;
